
import { defineComponent } from "vue";

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "dynamic-entity-list",
  components: {
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      selected: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      selectedEntity: [],
      selectedTrance: [],
      selectedCourseType: [],
      selectedCourse: [],
      selectedDistrict: [],
      tableData: [
        {
          id: 1,
          short_name: "ABC",
          name: "A.B.C Bangladesh-Turkish Technical School",
          address: "Nazirhat Municipality ,Fatickchari ",
          phone: "0312552697 ",
          mail: "abcbdtktschool@yahoo.com",
          website: "www.doulatpurabchighschool.edu.bd ",
        },
        {
          id: 2,
          short_name: "ACA",
          name: "ACA Technical Institute ",
          address: "Faterpur, Enayetnagar, Matlab Uttor, Chandpur ",
          phone: "0312510677  ",
          mail: "	aca.seip@gmail.com ",
          website: "www.aca.org ",
        },
      ],
      tableHeader: [
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
          selected: true,
        },
        {
          name: "Logo",
          key: "logo",
          sortable: true,
        },
        {
          name: "Short Name",
          key: "short_name",
          sortable: true,
          selected: true,
        },
        {
          name: "Full Name",
          key: "name",
          sortable: true,
          selected: true,
        },
        {
          name: "Address",
          key: "address",
          sortable: true,
          selected: true,
        },
        {
          name: "Phone",
          key: "phone",
          sortable: true,
          selected: true,
        },
        {
          name: "Email",
          key: "mail",
          sortable: true,
        },
        {
          name: "Website",
          key: "website",
          sortable: true,
        },
      ],
      tranceInfos: [
        {
          id: "1",
          name: "Tranche-1",
        },
        {
          id: "2",
          name: "Tranche-2",
        },
        {
          id: "3",
          name: "Tranche-3",
        },
        {
          id: "4",
          name: "Tranche-4",
        },
      ],
      courseTypes: [
        {
          id: "1",
          name: "New Entrant",
        },
        {
          id: "2",
          name: "Up Skill",
        },
        {
          id: "3",
          name: "Up Skill - Special (70-30)",
        },
        {
          id: "4",
          name: "New Entrant - Special (70-30)",
        },
      ],
      courseInfos: [
        {
          id: 1,
          name: "Welding & Fabrication (NE) ",
          code: "W&F (NE)",
          type: "New Entrant",
          Mgt: "Yes ",
          month: "3",
          trance: "TR-1 ",
          unit_cost: "43413",
        },
        {
          id: 2,
          name: "Electrical & Navigation Equipment Installation (NE) ",
          code: "E&N (NE) ",
          type: "New Entrant",
          Mgt: "Yes",
          month: "3",
          trance: "TR-1 ",
          unit_cost: "31674",
        },
      ],
      districtInfos: [
        {
          id: 1,
          name: "Dhaka",
        },
        {
          id: 2,
          name: "Khulna",
        },
        {
          id: 3,
          name: "Jessore",
        },
        {
          id: 4,
          name: "Magura",
        },
        {
          id: 5,
          name: "Bogra",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    headerFilter() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },

    selectedTableHeader() {
      const st = Array();
      this.tableHeader.map((item) => {
        if (item.selected) {
          st.push({ name: item.name, key: item.key });
        } else {
        }
      });
      return st;
    },
  },

  beforeMount() {
    this.selectedTableHeader();
  },
  computed: {},
  setup() {
    const AssociationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
      web_url: Yup.string().required().label("Web Url"),
      postcode: Yup.string().required().max(4).label("Postcode"),
      registration_number: Yup.string().required().label("Registration Number"),
      registration_authority: Yup.string()
        .required()
        .label("Registration Authority"),
      telephone: Yup.number().required().min(11).label("Telephone"),
      entity_name: Yup.string().required().label("Name"),
      entity_short_name: Yup.string().required().label("Short Name"),

      employeeEmail: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
      //presentDistrict: Yup.string().required().label("Present District"),
      name: Yup.string().required().label("Name"),
      designation: Yup.string().required().label("Designation"),
      gender: Yup.string().required().label("Gender"),
      mobile: Yup.number().required().min(11).label("Mobile"),
      dob: Yup.string().required().label("Date of Birth"),

      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .label("Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password didn't match!")
        .label("Confirm Password"),
    });
    return {
      AssociationSchema,
    };
  },
});
